import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';

import './App.css';
import Header from './components/header/Header';
import Featured from './components/featured/Featured';
import Clients from './components/clients/Clients';
import Footer from './components/footer/Footer';
import AgentWallet from './components/agent-wallet/AgentWallet';
import CrypoMystry from './components/crypto-mystry/CrypoMystry';
import SupportingToken from './components/supporting-token/SupportingToken';
import BillingInvoice from './components/billing-invoice/BillingInvoice';
import Contact from './components/contact/Contact';

const App = () => (
  <>
    <Container className="p-3">
      <Header />
    </Container>
    <Featured />
    <Clients />
    <AgentWallet />
    <CrypoMystry />
    <SupportingToken />
    <BillingInvoice />
    <Contact />
    <Footer />
  </>
);

export default App;
