import Container from 'react-bootstrap/Container';

import BILLING from './../../assets/images/billing-img.svg';
import PLAY from './../../assets/images/play-shape-blue.svg';
import STAR from './../../assets/images/star-shap.svg';
import POLY from './../../assets/images/poly-shape.svg';
import iOS from './../../assets/images/ios-pay.webp.svg';
import ANDROID from './../../assets/images/android-pay.webp.svg';
import ICON1 from './../../assets/images/icon-1.svg';
import ICON2 from './../../assets/images/icon-2.svg';
import ICON3 from './../../assets/images/icon-3.svg';

import "./BillingInvoice.css";
import { Col, Row } from 'react-bootstrap';
import SubHeading from '../SubHeading';

function BillingInvoice() {
  return (
    <div class="invoice-wrapper">
      <Container>
        <Row className='billingInvoice-row gap-4 mx-auto py-2 align-items-center'>
          <Col md={5}>
            <img src={BILLING} alt="agent wallet" className="d-inline-block agent-img w-100" />
            <img src={PLAY} alt="star" className="d-block mt-5 ms-5" />
          </Col>
          <Col md={6}>
            <img src={POLY} alt="star" className="d-block ms-4 float-end" />
            <SubHeading>Why not try it out yourself.</SubHeading>
            <p class="my-4">You can experience deposit and withdrawal from Chexkout here!</p>
            <div class="d-flex gap-2 mt-3 flex-column flex-sm-row flex-wrap">
              <input class="btn btn-success px-4" type="submit" value="Deposit" />
              <input class="btn btn-success px-4" type="submit" value="Withdraw" style={{
                background:'white',
                color:'#72bf44'
              }} />
            </div>
          </Col>
        </Row>

        <div class="text-center mt-5 get-start-wrapper">
          <img src={STAR} alt="bg" class="mb-2 star-icon" />
          <h3 class="fw-bold">Get Starred in few Minutes</h3>
          <p class="my-3">Coinbase supports a variety of the most popular digital currencies.</p>
          <Row className='mx-auto py-2 align-items-center get-started-blocks'>
            <Col md={4} sm={6} className="mb-2">
              <div class="bg-black rounded-2 py-4 px-2">
                <img src={ICON1} alt="bg" class="mb-2" />
                <h5 class="text-white">Create an account</h5>
                <p class="gs-desc">Can see all the public transaction</p>
              </div>
            </Col>
            <Col md={4} sm={6} className="mb-2">
              <div class="bg-black rounded-2 py-4 px-2">
                <img src={ICON2} alt="bg" class="mb-2" />
                <h5 class="text-white">Create an account</h5>
                <p class="gs-desc">Can see all the public transaction</p>
              </div>
            </Col>
            <Col md={4} sm={6} className="mb-2">
              <div class="bg-black rounded-2 py-4 px-2">
                <img src={ICON3} alt="bg" class="mb-2" />
                <h5 class="text-white">Create an account</h5>
                <p class="gs-desc">Can see all the public transaction</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default BillingInvoice;