import Container from 'react-bootstrap/Container';

import BITCOIN from './../../assets/images/bitcoin.svg';

import "./Contact.css";
import { Col, Row } from 'react-bootstrap';
import SubHeading from '../SubHeading';

function Contact() {
  return (
    <div class="contact-wrapper mb-5">
      <Container>
        <Row className='Contact-row gap-4 mx-auto py-2 align-items-center'>
          <Col md={6} class="contact-info">
            <h4 class="fw-bold">Contact Us to Learn More!<br /></h4>
            <p class="mb-5"></p>
            <div class="text-center">
              <img src={BITCOIN} alt="agent wallet" className="d-inline-block bitcoin-img" />
            </div>
          </Col>
          <Col md={6} className='contact-form'>
            <form id="contactform" action="#" className='p-4' method="post">
              <h5 class="fw-bold">Drop us your Message</h5>
              <p>We’d love to hear from you!</p>
              <div class="row">
                <div class="col-md-12 mb-4">
                  <label>Your Name</label>
                  <input type="text" class="form-control" id="fname" name="firstname" placeholder="Your Name" />
                </div>
                <div class="col-md-12 mb-4">
                  <label>Your Email</label>
                  <input type="text" class="form-control" id="email" name="email" placeholder="Email Address" />
                </div>
                <div class="col-md-12 mb-4">
                  <label>Your Phone Number</label>
                  <input type="text" class="form-control" id="phone" name="phone" placeholder="Phone Number" />
                </div>
                <div class="col-md-12 mb-4">
                  <label>Your Message</label>
                  <textarea id="subject" class="form-control" name="subject" placeholder="Type Your Message Here..."></textarea>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-4 d-flex gap-3">
                  <input type="checkbox" id="agree" name="agree" value="agree" />
                  <label for="agree" class="text-sm">By submitting this form, you confirm that you have read and agree to our<br /><a href="#" class="text-success">Terms & Conditions</a> and <a href="#" class="text-success">Privacy Policies</a></label>
                </div>
                <div class="col-md-12">
                  <input class="btn btn-success px-4" type="submit" value="Send Message" />
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;