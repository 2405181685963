import Container from 'react-bootstrap/Container';

import GLOBE from './../../assets/images/globe.svg';
import CIRCLE from './../../assets/images/circle-shape.svg';
import PLAY from './../../assets/images/play-shape-blue.svg';
import JACKPOT from './../../assets/images/jackpot.svg';
import XSHAPE from './../../assets/images/x-shape.svg';

import "./CrypoMystry.css";
import { Col, Row } from 'react-bootstrap';
import SubHeading from '../SubHeading';

function CrypoMystry() {
  return (
    <Container>
      <Row className='gap-4 mx-auto py-2 align-items-center'>
        <Col md={6}>
          <SubHeading>Player Crypto <span class="text-success">Withdrawal</span></SubHeading>
          <p class="my-4 pe-md-5">Players can withdraw crytpo (redeem game credit) directly,<br />less hustle for agents so they can focus on promotion and real customer services.</p>
          <img src={CIRCLE} alt="star" className="d-block circle" />
        </Col>
        <Col className='d-flex gap-4' md={5}>
          <img src={GLOBE} alt="agent wallet" className="d-inline-block agent-img w-100" />
          <img src={PLAY} alt="star" className="mt-5 agent-play" />
        </Col>
      </Row>

      <Row className="bg-black rounded-2 p-4 mb-3">
        <Col lg={4} className='d-flex gap-3 align-items-center'>
          <img src={JACKPOT} alt="jackpot" className="d-block" />
          <div>
            <h5 class="text-white fw-bold">Mystery Progressive Jackpot</h5>
            <p class="text-sm text-white">One of a kind mystery progressive jackpot for player who buy credit through crypto payment</p>
          </div>
        </Col>
        <Col lg={8} className='align-self-center text-end x-btns'>
          <div className='py-3 font-md mx-2 x' style={{
            display: 'inline-block',
          }}>
            <a href='#' className='btn btn-success py-3 px-4 x'>123,456.78</a>
            <span style={{ fontSize: '18px', display: 'block', textAlign: 'center', color: '#72bf44', marginTop: '0.5rem' }}>MEGA</span>
          </div>
          <div className='py-3 font-md mx-2 x' style={{
            display: 'inline-block',
          }}>
            <a href='#' className='btn py-3 px-4 btn-outline-success  x' >3,456.78</a>
            <span style={{ fontSize: '18px', display: 'block', textAlign: 'center', color: '#72bf44', marginTop: '0.5rem' }}>GRAND</span>
          </div>

          <div className='py-3 font-md mx-2 x' style={{
            display: 'inline-block',
          }}>
            <a href='#' className='btn py-3 px-4 btn-outline-success  x' >3,456.78</a>
            <span style={{ fontSize: '18px', display: 'block', textAlign: 'center', color: '#72bf44', marginTop: '0.5rem' }}>MAJOR</span>
          </div>

          <div className='py-3 font-md mx-2 x' style={{
            display: 'inline-block',
          }}>
            <a href='#' className='btn py-3 px-4 btn-outline-success  x' >3,456.78</a>
            <span style={{ fontSize: '18px', display: 'block', textAlign: 'center', color: '#72bf44', marginTop: '0.5rem' }}>MINOR</span>
          </div>
        </Col>
      </Row>
      <img src={XSHAPE} alt="jackpot" className="d-block x-shape" />
    </Container>
  );
}

export default CrypoMystry;