// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/down-dir.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact-wrapper{\n    margin-top: 170px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: contain;\n}\n\n.bitcoin-img{\n    max-width: 250px;\n    width: 100%;\n}\n\n#contactform{\n    background-color: #72BF441A;\n}\n\nlabel{\n    color: #6B7280;\n}\n\ninput[type=\"checkbox\"]{\n    height: 18px;\n    width: 18px;\n}\n\ninput[type=\"submit\"]{\n    padding: 12px;\n}\n\ntextarea{\n    height: 130px;\n}", "",{"version":3,"sources":["webpack://src/components/contact/Contact.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yDAA2D;IAC3D,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".contact-wrapper{\n    margin-top: 170px;\n    background-image: url('./../../assets/images/down-dir.svg');\n    background-repeat: no-repeat;\n    background-size: contain;\n}\n\n.bitcoin-img{\n    max-width: 250px;\n    width: 100%;\n}\n\n#contactform{\n    background-color: #72BF441A;\n}\n\nlabel{\n    color: #6B7280;\n}\n\ninput[type=\"checkbox\"]{\n    height: 18px;\n    width: 18px;\n}\n\ninput[type=\"submit\"]{\n    padding: 12px;\n}\n\ntextarea{\n    height: 130px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
