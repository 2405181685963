import Container from 'react-bootstrap/Container';

import SUPPORTING from './../../assets/images/support-token.svg';
import CIRCLE from './../../assets/images/circle-shape.svg';
import SQUARE from './../../assets/images/square-shape.svg';
import PLAY from './../../assets/images/play-shape-blue.svg';

import "./SupportingToken.css";
import { Col, Row } from 'react-bootstrap';
import SubHeading from '../SubHeading';

function SupportingToken() {
  return (
    <div class="wrapper">
      <Container className='mt-5 pt-4'>
        <img src={CIRCLE} alt="star" className="d-block ms-5 mt-5" />
        <div class="text-center">
          <div class="position-relative">
            <SubHeading>The Ever-Expanding List of <span class="text-success">Supporting</span> Tokens</SubHeading>
            <img src={PLAY} alt="star" className="d-block-inline play-icon" />
          </div>
          <p class="my-5"> </p>
          {/* <a href='#' className='btn py-3 px-5 btn-success'>See More Details</a> */}
          <img src={SUPPORTING} alt="star" className="d-block my-5 w-100" />
          <img src={SQUARE} alt="star" className="d-block-inline square-shape" />
        </div>
      </Container>
    </div>
  );
}

export default SupportingToken;