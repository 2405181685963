// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/feature-bg.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1{\n    color:#163300;\n    font-size: 50px;\n    font-weight: 600;\n}\nh1 span{\n    color: #72bf44;\n}\n#featured-wrapper{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: contain;\n}\n\n#get-started{\n    padding: 12px 35px;\n    font-weight: 300;\n}\n\n@media (max-width: 767px) {\n    #featured-wrapper{\n        text-align: center;\n    }\n    h1 {\n        font-size: 40px;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/featured/Featured.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,yDAA6D;IAC7D,4BAA4B;IAC5B,wBAAwB;AAC5B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;IACtB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":["h1{\n    color:#163300;\n    font-size: 50px;\n    font-weight: 600;\n}\nh1 span{\n    color: #72bf44;\n}\n#featured-wrapper{\n    background-image: url('./../../assets/images/feature-bg.png');\n    background-repeat: no-repeat;\n    background-size: contain;\n}\n\n#get-started{\n    padding: 12px 35px;\n    font-weight: 300;\n}\n\n@media (max-width: 767px) {\n    #featured-wrapper{\n        text-align: center;\n    }\n    h1 {\n        font-size: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
