import Container from 'react-bootstrap/Container';

import BITCOIN_PHONE from './../../assets/images/bitcoin-phone.png';
import "./Featured.css";
import { Col, Row } from 'react-bootstrap';

function Featured() {
  return (
    <div id="featured-wrapper">
      <Container>
        <Row className='align-items-center'>
          <Col md={6} className='align-self-center'>
            <h1>Gaming-oriented Web3 Crypto Payment <span>Gateway</span></h1>
            {/* <p class="py-3">The crypto payment gateway designed for and by the online gaming industry</p> */}
            {/* <a href='#' id="get-started" className='btn btn-success'>Get Started Now</a> */}
          </Col>
          <Col md={6}>
            <img src={BITCOIN_PHONE} alt="logo" className="d-inline-block align-text-top w-100" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Featured;