import Container from 'react-bootstrap/Container';

// import AIRBNB from './../../assets/images/airbnb.png';
// import FEDEX from './../../assets/images/fedex.png';
// import GOOGLE from './../../assets/images/google.png';
// import HUBSPOT from './../../assets/images/hubspot.png';
// import MICROSOFT from './../../assets/images/microsoft.png';
// import WALMART from './../../assets/images/walmart.png';
// import PLAY from './../../assets/images/play-shape-green.svg';

import MOTIX from './../../assets/images/Motix_Logo.png';
import ARTSBITE from './../../assets/images/Artsbite_Logo.png';
import POKER3 from './../../assets/images/poker3_logo.png';
import CLUBSPARTA from './../../assets/images/clubSparta_logo.png';

import "./Clients.css";
import { Row } from 'react-bootstrap';

function Clients() {
  return (
    <div id="client-wrapper" class="my-5">
      <Container>
        <Row className='clients-row gap-2 mx-auto py-2 justify-content-center'>
          <img src={MOTIX} alt="motix" className="d-inline-block" />
          <img src={ARTSBITE} alt="artsbite" className="d-inline-block" />
          <img src={POKER3} alt="poker3" className="d-inline-block" />
          <img src={CLUBSPARTA} alt="clubSparta" className="d-inline-block" />
          {/* <img src={MICROSOFT} alt="microsoft" className="d-inline-block" />
          <img src={WALMART} alt="walmart" className="d-inline-block" />
          <img src={PLAY} alt="play" className="client-play-icon" /> */}
        </Row>
      </Container>
    </div>
  );
}

export default Clients;