// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/billing-bg.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../assets/images/doted-left.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invoice-wrapper{\n    margin-top: 100px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: contain;\n    position: relative;\n}\n\n.invoice-wrapper::after{\n    content: \"\";\n    width: 100%;\n    height: 400px;\n    display: inline-block;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-repeat: no-repeat;\n    background-size: contain;\n    background-position: right;\n    position: absolute;\n    bottom: -250px;\n}\n\n.star-icon{\n    width: 20px;\n}\n\n.get-started-blocks{\n    max-width: 1000px;\n}\n\n.gs-desc{\n    color: #B7B7B9;\n    font-size: 13px;\n}\n\n", "",{"version":3,"sources":["webpack://src/components/billing-invoice/BillingInvoice.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yDAA6D;IAC7D,4BAA4B;IAC5B,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,aAAa;IACb,qBAAqB;IACrB,yDAA6D;IAC7D,4BAA4B;IAC5B,wBAAwB;IACxB,0BAA0B;IAC1B,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".invoice-wrapper{\n    margin-top: 100px;\n    background-image: url('./../../assets/images/billing-bg.svg');\n    background-repeat: no-repeat;\n    background-size: contain;\n    position: relative;\n}\n\n.invoice-wrapper::after{\n    content: \"\";\n    width: 100%;\n    height: 400px;\n    display: inline-block;\n    background-image: url('./../../assets/images/doted-left.svg');\n    background-repeat: no-repeat;\n    background-size: contain;\n    background-position: right;\n    position: absolute;\n    bottom: -250px;\n}\n\n.star-icon{\n    width: 20px;\n}\n\n.get-started-blocks{\n    max-width: 1000px;\n}\n\n.gs-desc{\n    color: #B7B7B9;\n    font-size: 13px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
