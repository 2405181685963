// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/images/left-dir.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../assets/images/dotted-right.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: 250px;\n    background-position: top right;\n    position: relative;\n}\n.wrapper::after{\n    content: \"\";\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    background-repeat: no-repeat;\n    /* background-size: 250px; */\n    background-position: bottom left;\n    background-size: contain;\n    display: inline-block;\n    width: 100%;\n    height: 500px;\n    position: absolute;\n    bottom: 0;\n}\n.circle{\n    position: relative;\n    left:-30px;\n}\n\n.play-icon{\n    position: absolute;\n    right: 90px;\n}\n\n.square-shape{\n    margin-left: 50%;\n}", "",{"version":3,"sources":["webpack://src/components/supporting-token/SupportingToken.css"],"names":[],"mappings":"AAAA;IACI,yDAA2D;IAC3D,4BAA4B;IAC5B,sBAAsB;IACtB,8BAA8B;IAC9B,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,yDAA+D;IAC/D,4BAA4B;IAC5B,4BAA4B;IAC5B,gCAAgC;IAChC,wBAAwB;IACxB,qBAAqB;IACrB,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,SAAS;AACb;AACA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".wrapper{\n    background-image: url('./../../assets/images/left-dir.svg');\n    background-repeat: no-repeat;\n    background-size: 250px;\n    background-position: top right;\n    position: relative;\n}\n.wrapper::after{\n    content: \"\";\n    background-image: url('./../../assets/images/dotted-right.svg');\n    background-repeat: no-repeat;\n    /* background-size: 250px; */\n    background-position: bottom left;\n    background-size: contain;\n    display: inline-block;\n    width: 100%;\n    height: 500px;\n    position: absolute;\n    bottom: 0;\n}\n.circle{\n    position: relative;\n    left:-30px;\n}\n\n.play-icon{\n    position: absolute;\n    right: 90px;\n}\n\n.square-shape{\n    margin-left: 50%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
