import Container from 'react-bootstrap/Container';

import AGENT from './../../assets/images/agent-wallet.svg';
import DIRECTION from './../../assets/images/direction-left.svg';
import STAR from './../../assets/images/star-shap.svg';

import "./AgentWallet.css";
import { Col, Row } from 'react-bootstrap';
import SubHeading from '../SubHeading';

function AgentWallet() {
  return (
      <Container>
        <Row className='AgentWallet-row gap-4 mx-auto py-2'>
          <Col md={5} className='order-2 ordre-md-1'>
            <img src={AGENT} alt="agent wallet" className="d-inline-block agent-img w-100" />
            <img src={STAR} alt="star" className="d-block mt-5" />
          </Col>
          <Col md={6} className='order-1 order-md-2'>
            <img src={STAR} alt="star" className="d-block ms-4" />
            <img src={DIRECTION} alt="agent wallet" className="d-inline-block my-2" />
            <SubHeading><span class="text-success">P2P</span> Direct Payment to Agent</SubHeading>
            <p class="my-4">Players pay directly to their respective agents' crytpo-wallet, no staging walletAgents get their tokens instantly and direclty</p>
            <div class="d-flex gap-2 mt-3">
              {/* <div>
                <h4 class="text-success fw-bold mb-3">21M+</h4>
                <p class="text-sm">Registered coins</p>
              </div>
              <div>
                <h4 class="text-success fw-bold mb-3">19M+</h4>
                <p class="text-sm">Registered bitcoin</p>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
  );
}

export default AgentWallet;