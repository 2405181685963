import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import LOGO from './../../assets/images/logo-white.png';
import "./Footer.css";
import { Col, Row } from 'react-bootstrap';

function Footer() {
  return (
    <div class="bg-black" id="footer-wrapper">
      <Container>
        <Row>
          <Col md={5} className='align-self-center footer-meta'>
            <img src={LOGO} alt="logo" width="200" className="d-inline-block align-text-top" />
            {/* <p class="text-white mt-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br/> sed do eiusmod tempor
            </p> */}
          </Col>
          <Col>
            <p class="text-white fw-bold">Resources</p>
            <Nav className="flex-column">
              <Nav.Link className='text-white ps-0 mb-2' href="#serv">Services</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#fin">Fintech Services</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#about">About Us</Nav.Link>
            </Nav>
          </Col>
          <Col>
            <p class="text-white fw-bold">Company</p>
            <Nav className="flex-column">
              <Nav.Link className='text-white ps-0 mb-2' href="#serv">Services</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#success">Success History</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#setting">Setting & Privacy</Nav.Link>
            </Nav>
          </Col>
          <Col>
            <p class="text-white fw-bold">Contact Us</p>
            <Nav className="flex-column">
              <Nav.Link className='text-white ps-0 mb-2' href="#serv">Location</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#success">Email</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#setting">Telephone</Nav.Link>
            </Nav>
          </Col>
          <Col>
            <p class="text-white fw-bold">Social Media</p>
            <Nav className="flex-column">
              <Nav.Link className='text-white ps-0 mb-2' href="#serv">Facebook</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#success">Twitter</Nav.Link>
              <Nav.Link className='text-white ps-0 mb-2' href="#setting">Instagram</Nav.Link>
            </Nav>
          </Col>
        </Row>
      </Container>
      <p class="mt-5 mb-2 text-white text-center">© 2023 Chexkout. All Rights Reserved.</p>
    </div>
  );
}

export default Footer;